code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
 
:root {
  --title-size-laptop: 3.5vw;
  --header-size-laptop: 3vw;
  --content-text-size-laptop: 1.5vw;

  --title-size-mobile: 8vw;
  --header-size-mobile: 8vw;
  --content-text-size-mobile: 4vw;

  --primary-color: #5cbc33;
  --text-color: rgb(83, 83, 83);
  --body-color: hsl(0, 0%, 6%);
  --body-font: Titillium Web;
  --large-font-modern: Bebas Neue;
  --large-font: sans;
  --second-font: "Dancing Script", cursive;
  --h2-font-size: 1.25rem;
  --small-font-size: 0.813rem;
  --font-semi-bold: 600;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}