.menu {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 998;
    align-items: center;

    padding-top: 1.5%;

    background-color: white;
}


.menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
}

.menu-logo {
    text-decoration: none;
    background: linear-gradient(to right, #5cbc33, rgb(48, 159, 129));
    background-clip: text;
    color: transparent;
    
    font-weight: 800;
    font-size: 2.5rem;
}

.navmenuContainer {
    height: 80px;
    width: 80% vw;
    margin: none;
    padding: none;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
}

.menu-links {
    color: #3b4041;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 80px;
    text-decoration: none;

    font-size: 1.5rem;
}

li img {
    scale: 1;
    margin: none;
    padding: none;
}

#dropdown-basic {
    border-radius: 0.1;

    font-size: 1.5rem;
    color: rgb(83, 83, 83);
    margin: none;

    scale: 1;
}

/* Homeowners dropdown triangle */
.customDropDownToggle {
    background-color: transparent !important;
    font-size: 1.6rem;
    color: #3b4041;
    text-decoration: none !important;
    border: none !important;
    box-shadow: none !important;
}

.customDropDownToggle:hover, .customDropDownToggle:focus, .customDropDownToggle:active {
    background-color: transparent !important;
    color: #3b4041 !important;
    border: none !important;
    box-shadow: none !important;
}

/* Homeowner dropdown word */
.submenuLink {
    font-size: 1.6rem;
    color: #474a4a;
    text-decoration: none;
}

.dropdownLinks {
    padding: 0.5rem 1rem;
    height: 80px;
    text-decoration: none;
    font-size: 1.5rem;
    color: #3b4041;
}

.resourcesDropdown {
    background-color: white;
    font-size: 1.5rem;
    color: #3b4041;
    text-decoration: none;
}

@media screen and (max-width: 1270px) {
    .dropdownMobile {
        width: 100%;
    }
    
    .menu-mobile {
        z-index: 999;
        display: flex;
        flex-direction: column;

        padding-left: 5%;

        position: absolute;
        left: 0%;
        top: 0%;
        height: 100%;
        width: 100%;
        overflow: hidden;

        background-color: white;
        color: black;
        /* align-items:left; */
    }

    .mobileMenuBar {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 5%;
        padding-top: 2%;
        padding-bottom: 2%;

        /* this margin dictates the margin of the entire mobile menu bar */
        margin: 2%;

        position: relative;
    }

    .mobile-menu-right-column {
        display: flex;
        flex-direction: row;
        margin-left: 5%;
        margin-top: -1%;

        scale: 0.8;

        position: absolute; 
        right: 0; 
    }

    .menu-toggle-button {
        border: none;
        font-size: 1.6rem;
        height: 20px;
        width: 25px;
        background-color: white;
        position: relative;
        top: -5px;
    }

    .mobile-menu-left-column .menu-logo {
        font-size: 1.8rem;
        position: relative;
        left: 5%;
    }

    .menu-toggle-button-inner {
        border: none;
        background-color: white;
        
        display: flex;
        
        width: 35px;
        height: 35px;
        font-size: 1.8rem;

        margin: none;
        padding: none;

        position: relative;
        left: 5px;
        top: 25px;
    }
}

/*
mobileMenuBar
    mobile-menu-left-column
        menu-logo
    mobile-menu-right-column
        menu-toggle-button
*/