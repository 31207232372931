.summaryContainer {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerLink {
    text-decoration: none;
}

.summaryContainer:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.summaryContainer h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: black;
}

.summaryContainer p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

.summaryImageContainer {
    width: 100%;
    height: 200px; /* Set a fixed height */
    overflow: hidden; /* Hide overflow */
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 5%;
}

.summaryImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container without distortion */
}
