/* popupContainer */
.popupContainer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  background-color: white;
  overflow-x: hidden;
  transition: width 0.5s ease-in-out;
  box-shadow: -2px 0 5px rgba(0,0,0,0.5);
  padding: 20px;
  z-index: 1000;
}

.popupContainer.open {
  width: 40%;
}

/* mainContent */
.mainContent {
  width: 80%;
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.5s ease-in-out;
}

.mainContent.shrink {
  width: 60%;
}

/* columnContainer */
.columnContainer {
  margin-top: 5%;
  padding-left: 5%;
  display: block;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Labels and Inputs */
.popupContainer label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.popupContainer input {
  display: block;
  width: 100%;
  margin-bottom: 16px;
  padding: 8px;
  box-sizing: border-box;
}

/* Columns */
.columnLeft2, .columnRight2 {
  flex: 1;
  padding: 10px;
  transition: width 0.5s ease-in-out;
}

/* Buttons */
.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-left: 5%;
}

.buttons button, .buttons .variant1 {
  padding: 2%;
  font-size: 16px;
}

.myDonationsBodyContent {
  display: flex;
  flex-direction: row;
}

.donationsColumnLeft {
  flex: 30%;
  margin-right: 7%;
}

.donationsColumnRight {
  flex: 70%;
}

.queryEmailForm {
  display: flex;
  flex-direction: column;
}

.busy {
  font-size: 1rem;
  color: darkgray;
}

@media screen and (max-width: 780px) {
  .myDonationsBodyContent {
    display: flex;
    flex-direction: column;
  }

  .donationsColumnLeft {
    margin-bottom: 10%;
  }
  
  .popupContainer.open {
    width: 100%;
  }

  .columnContainer {
    width: 95%;
  }
}

