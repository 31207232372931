.variant1 {
    font-weight: bold;
    border-radius: 10px;
    width: fit-content;
    padding: 2px;
    padding-right: 2%;
    padding-left: 2%;
    margin-top: 3%;
    margin-right: 3%;
    
    background-color: #5cbc33;
    color: white;
    border: none;
}

.variant1:hover {
    border: none;
    background-color: #3d8e1a;
    color: white;
}

.variant2 {
    font-weight: bold;
    border-radius: 10px;
    width: fit-content;
    padding: 2px;
    padding-right: 2%;
    padding-left: 2%;
    margin-top: 3%;
    margin-right: 3%;
    
    background-color: white;
    color: black;
    border: 1px solid  lightgray;
}

.variant2:hover {
    border: 1px solid black;
}