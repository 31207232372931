.signUpContainer {
    height: fit-content;
    z-index: 996;
    background-color: white;
    display: block;
    margin: auto;
    padding: 5%;
    
    background-color: #f9f9f9;
    box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.1),
    -10px -10px 10px white;
    
}
.parentdiv {
    width:100%;
    height:80vh;
    display:flex;
    align-items: center;
}
.inputElementsParent{
    margin:auto;

    align-items:center;
}
.inputfield{
    width: 100%;
}
