.loginContainer {
    height: fit-content;
    width: fit-content;
    z-index: 996;
    background-color: white;
    display: block;
    margin: auto;
    padding: 2%;

    background-color: #f9f9f9;
    box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.1),
    -10px -10px 10px white;
}

.parentdiv1 {
    margin-top: 15%;
    margin-bottom: 15%;
    
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    scale: 1.5;
}
