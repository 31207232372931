body {
    font-size: 16px;
}

h1 {
    font-family: var(--large-font);
    font-weight: bold;
    font-size: var(--title-size-laptop);
}

h2 {
    font-family: var(--large-font);
    font-weight: bold;
    font-size: var(--header-size-laptop);   
}

h4 {
    font-family: var(--body-font);
    color: var(--text-color);
    font-size: var(--content-text-size-laptop);
}

h5 {
    margin-bottom: 1%;
}

.bodyContainer {
    display: flex;
    justify-content: center;
    margin:0 auto;
    padding-left: 3%;
    padding-top: 3%;

    /* border: 1px solid black; */
    width: 80%;
    height: 100%;
}

.columnLeft {
    flex: 50%;
    height: 60%;
    align-self: center;
    margin-left: auto;
    margin-top: 5%;

}

.columnRight {
    flex: 50%;
    width: 50px;
    height: auto;
    margin: 0 auto;
    padding: 10px;
    position: relative;
    left: 10%;


    /* border: 1px solid black; */
}

.mainImage {
    max-width: 70%;
    height: auto; 
    margin: none;
    padding: 5%;
    scale: 1.2;
}

.registerButton {
    font-weight: bold;
    border-radius: 10px;
    width: 18%;
    padding: 2px;
    margin-top: 3%;
    margin-right: 3%;
    
    background: linear-gradient(to right, #79d252, rgb(74, 213, 176));
    border: none;
}

.registerButton:hover {
    border: none;
    background-color: #3d8e1a;
    color: white;
}

.contactButton {
    font-weight: bold;
    border-radius: 10px;
    width: 18%;
    padding: 2px;
    margin-top: 3%;
    margin-right: 3%;
    
    background-color: white;
    color: black;
    border: 1px solid  lightgray;
}

.contactButton:hover {
    border: 1px solid black;
}

.stats {
    padding-top: 5%;
    display: flex;
    flex-direction: row;
    margin: auto;
    padding: none;
    justify-content: center;
    align-items: center;
    flex: 45%;
}

.volunteerStats, .homeownersStats, .fruitStats {
    position: relative;
    flex: 15%;
    margin: none;
    padding: none;
    justify-content: center;
    align-items: center;
}

.vimage{
    max-width: 35%;
}

.himage{
    max-width: 35%;
}
.fimage{
    max-width: 35%;
}

#invisible {
    border: none;
    background-color: inherit;
    color: white;
}

.blogs{
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    padding:none;
    margin:auto;

    margin-bottom: 3%;
}

.readAllBlogsAndsResources {
    margin-bottom: -1%;
    text-decoration: none;
}

/* css for the cool blogs and resources animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.fadecard {
    opacity: 0; 
    transform: scale(0.9);
}

.fadecard.animate {
    animation: fadeIn 1s forwards; 
}


.footer {
    padding: 3%;
    margin-top: 5%;
    width: 100%;
    height: 5%;
    background-color: lightgray;
}

.customLink {
    text-decoration: none;
    color: inherit;
}

.footerTitle {
    font-weight: bold;
    color: #2f601b;
}

@media screen and (max-width: 780px) {
    /* words and headers */
    .stats {
        flex-direction: column;
    }
    
    .columnLeft {
        flex: 100%;
        width: 100%;
    }
    
    h1 {
        font-size: var(--title-size-mobile)
    }
    
    h2 {
        font-size: var(--header-size-mobile);
    }

    h4 {
        font-size: var(--content-text-size-mobile)
    }

    .bodyContainer {
        flex-direction: column;
    }

    .columnRight {
        width: 100%;
        height: auto;
    }
    
    .mainImage {
        height: auto; 
        align-items: center;
        justify-content: center;
        padding: 5%;
        scale: 1;
    }
  }
